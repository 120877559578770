<template>
  <el-row :gutter="20" style="margin-top: 5vh">
    <el-col :span="6"></el-col>
    <el-col :span="7" class="bg-black">
      <h2>Plus Device Simulator</h2>
    </el-col>
    <el-col :span="5" class="bg-black">
      <img
        src="./easy-laser-plus-logo.png"
        class="plus-device-simulator-logo"
      />
    </el-col>
    <el-col :span="6"></el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col :span="6"> </el-col>
    <el-col :span="12" class="bg-grey">
      <div class="about-text">
        <span>
          The Easy-Laser Plus Device Simulator is used to simulate a Client
          (like the XT Alignment App) connecting to the Easy-Laser Plus and
          uploading files.
        </span>
        <hr />
      </div>
    </el-col>
    <el-col :span="6"> </el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col :span="6"></el-col>
    <el-col :span="12" class="bg-grey">
      <el-form label-position="left" label-width="150px" model="left">
        <div class="device-container">
          <el-form-item label="Easy-Laser Plus URL">
            <el-input v-model="deviceSimulator.connectionString" disabled />
          </el-form-item>
          <el-form-item label="Status">
            <el-input v-model="deviceSimulator.connectionStatus" disabled />
          </el-form-item>
          <template v-if="deviceSimulator.isConnected">
            <template v-if="deviceSimulator.isAuthenticated">
              <el-form-item label="Organization">
                <el-input v-model="deviceSimulator.orgName" disabled />
              </el-form-item>
              <el-form-item label="Client">
                <el-input v-model="deviceSimulator.clientName" disabled />
              </el-form-item>
            </template>
            <el-form-item v-if="!deviceSimulator.isAuthenticated">
              <el-input v-model="deviceSimulator.userCode">
                <template #append>
                  <el-button :icon="CopyDocument" @click="onCopyUserCode()" />
                </template>
              </el-input>
              <div class="infotext">
                Copy and paste the user code and create a new app <br />by using
                the the code in the portal
              </div>
            </el-form-item>
          </template>
          <el-form-item v-if="deviceSimulator.isAuthenticated">
            <el-button
              size="large"
              type="primary"
              @click="handleUpload()"
              :icon="UploadFilled"
              :loading-icon="Eleme"
              :loading="deviceSimulator.uploadingBtn.isUploadingFiles"
            >
              {{ deviceSimulator.uploadingBtn.btnText }}</el-button
            >
          </el-form-item>
          <el-form-item v-if="deviceSimulator.isConnected">
            <el-button
              size="large"
              type="danger"
              @click="handleConnect()"
              :icon="Close"
              >Unassign app</el-button
            >
          </el-form-item>
          <el-form-item v-if="!deviceSimulator.isConnected">
            <el-button
              size="large"
              type="primary"
              @click="handleConnect()"
              :icon="Refresh"
              >Assign app</el-button
            >
          </el-form-item>
        </div>
      </el-form>
    </el-col>
    <el-col :span="6"></el-col>
  </el-row>
</template>
<script lang="ts" setup>
import { reactive, ref, onBeforeMount } from "vue";
import { ElNotification } from "element-plus";
import {
  UploadFilled,
  Refresh,
  Close,
  Eleme,
  CopyDocument,
} from "@element-plus/icons-vue";
import ResClient from "resclient";
import axios from "axios";
import * as blobData from "@azure/storage-blob";

const apiVersion = "v1";

const fileData = [
  {
    name: "Pump report v1.pdf",
    applicationType: "Report",
    tags: { asset: "12345", program: "Shaft" },
    fileDiscName: "1.pdf",
    createdate: "",
  },
  {
    name: "Pump measurement image 1.jpg",
    applicationType: "Image",
    tags: { asset: "23456", program: "Shaft" },
    fileDiscName: "2.jpg",
    createdate: "",
  },
  {
    name: "Compressor report adjusted.pdf",
    applicationType: "Report",
    tags: { asset: "23456", program: "Values" },
    fileDiscName: "3.pdf",
    createdate: "",
  },
  {
    name: "Compressor measurement image 1.jpg",
    applicationType: "Image",
    tags: { asset: "23456", program: "Values" },
    fileDiscName: "4.jpg",
    createdate: "",
  },
  {
    name: "Cooling pump report v1.pdf",
    applicationType: "Report",
    tags: { asset: "34567", program: "Shaft" },
    fileDiscName: "5.pdf",
    createdate: "",
  },
  {
    name: "Empty report.xlsx",
    applicationType: "Report",
    fileDiscName: "6.xlsx",
    createdate: "",
  },
  {
    name: "Pump measurement image 2.png",
    applicationType: "Image",
    fileDiscName: "7.png",
    createdate: ""
  },
  {
    name: "Pump measurements.zip",
    applicationType: "Measurement",
    fileDiscName: "8.zip",
    createdate: ""
  },    
];

let credentials: any = null;

let authentication = {
  token: "",
  refresh_token: "",
  token_type: import.meta.env.VUE_APP_TOKEN_TYPE,
  tokenUrl: import.meta.env.VUE_APP_TOKEN_URL,
};

onBeforeMount(async () => {
  await createClient();
});

// do not use same name with ref
const deviceSimulator = reactive({
  connectionString: import.meta.env.VUE_APP_RESGATE_URL,
  isConnected: false,
  isAuthenticated: false,
  validUserCode: false,
  userCode: "",
  connectionStatus: "Not assigned",
  orgName: "",
  clientName: "",
  uploadingBtn: {
    isUploadingFiles: false,
    btnText: "Upload test files",
  },
});

const options = reactive({
  token: "",
  reconnect: false,
  device_code: "",
  client_Id: import.meta.env.VUE_APP_AUTH_CLIENT_ID,
});

const serviceClient = ref<ResClient>();

async function createClient(): Promise<void> {
  serviceClient.value = await init();
}

async function getUserCode(): Promise<void> {
  if (serviceClient.value !== undefined) {
    const userCode = await getToken();
    if (userCode !== undefined) {
      showMessage("Fetch assignment code", "success");
      deviceSimulator.userCode = userCode;
      deviceSimulator.validUserCode = true;
      const result: any = await handlePortalConnection();
      deviceSimulator.clientName = result.clientName;
      deviceSimulator.orgName = result.orgName;
      deviceSimulator.isAuthenticated = true;
      deviceSimulator.connectionStatus = "Assigned";
    }
  }
}

async function handleConnect(): Promise<void> {
  if (serviceClient.value !== undefined) {
    if (!deviceSimulator.isConnected) {
      try {
        serviceClient.value.connect();
        deviceSimulator.connectionStatus = "Pending";
        showMessage("Connected to Easy-Laser Plus!", "success");
        getUserCode();
      } catch (e) {
        console.error(e);
      }
    } else {
      serviceClient.value.disconnect();
      showMessage("Disconnected from Easy-Laser Plus!", "info");
      deviceSimulator.connectionStatus = "Not assigned";
      deviceSimulator.isAuthenticated = false;
    }
  }
  deviceSimulator.isConnected = !deviceSimulator.isConnected;
}

async function handleUpload(): Promise<void> {
  deviceSimulator.uploadingBtn = {
    ...deviceSimulator.uploadingBtn,
    isUploadingFiles: true,
    btnText: "Uploading test files...",
  };
  if (serviceClient.value !== undefined) {
    const result: boolean = await uploadFiles();
    deviceSimulator.uploadingBtn = {
      ...deviceSimulator.uploadingBtn,
      isUploadingFiles: false,
      btnText: "Upload test files",
    };
    if (result) {
      showMessage(`Succesfully uploaded test files`, "success");
    } else {
      showMessage(`Failed to upload test files`, "error");
    }
  }
}

function onCopyUserCode(): void {
  navigator.clipboard.writeText(deviceSimulator.userCode);
}

const showMessage = (message: string, severity: string) => {
  switch (severity) {
    case "success":
      ElNotification({
        message,
        showClose: true,
        type: "success",
      });
      break;
    case "info":
      ElNotification({
        message,
        showClose: true,
        type: "info",
      });
      break;
    case "warning":
      ElNotification({
        message,
        showClose: true,
        type: "warning",
      });
      break;
    case "error":
      ElNotification({
        message,
        showClose: true,
        type: "error",
      });
      break;
    default:
      break;
  }
};

// ServiceClient functions
async function init() {
  try {
    const client = new ResClient(import.meta.env.VUE_APP_RESGATE_URL);
    return client;
  } catch (e) {
    showMessage("Connection to Easy-Laser Plus failed!", "error");
    console.error(e);
  }
}

function sleep(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

async function getToken(): Promise<string | undefined> {
  const request: any = await serviceClient?.value
    ?.call(`${apiVersion}.credentials`, "code", { client_id: options.client_Id })
    .catch((error: string) => console.error(error));
  options.device_code = request.device_code;
  return request.user_code;
}

async function handlePortalConnection(): Promise<void> {
  while (!credentials) {
    try {
      await sleep(3000);
      credentials = await serviceClient?.value
        ?.call(`${apiVersion}.credentials`, "token", {
          device_code: options.device_code,
          client_id: options.client_Id,
        })
        .catch((error) => {
          if (error._message !== "authorization_pending") {
            console.error(error);
          }
        });
    } catch (e) {
      console.log(e);
    }
  }
  authentication = { ...authentication, ...credentials };
  const result: any = await authenticate();
  if (result) {
    return result;
  }
}
async function authenticate() {
  console.log("Authenticate with Easy-Laser Plus");

  //Get access token
  const authOptions = {
    url: import.meta.env.VUE_APP_TOKEN_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      grant_type: "refresh_token",
      refresh_token: authentication.refresh_token,
      client_id: options.client_Id,
    },
  };

  //Authenticate
  const auth: any = await axios(authOptions).catch((error: string) => {
    console.error(error);
  });
  if (auth instanceof Object) {
    if (auth.error) {
      showMessage("Authentication failed", "error");
      console.error(auth.error);
    }
    showMessage("App is assigned to Easy-Laser Plus", "success");
    authentication.token = auth.data.access_token;

    //Authenticate
    // @ts-ignore
    options.client_Id = await serviceClient?.value
      ?.authenticate("token", "jwt", {
        Authorization: `Bearer ${authentication.token}`,
      })
      .catch((error: string) => console.error(error));

    const cloudClient: any = await serviceClient?.value
      ?.get(`${apiVersion}.clients.${options.client_Id}`)
      .catch((error: string) => console.error(error));
    const cloudOrg: any = await serviceClient?.value
      ?.get(`${apiVersion}.orgs.${cloudClient.orgId}`)
      .catch((error: string) => console.error(error));
    return { clientName: cloudClient.name, orgName: cloudOrg.name };
  }
}

// Upload files functions

async function createFile(index: number) {
  const file = fileData[index];
  file.createdate = new Date(Date.now()).toISOString();
  // eslint-disable-next-line no-debugger
  debugger;
  let result = await serviceClient?.value
    ?.call(`${apiVersion}.clients.${options.client_Id}.files`, "new", file)
    .catch((error: string) => console.error(error));
  if (result !== undefined) {
    result = { ...result, fileDiscName: file.fileDiscName };
  }
  return result;
}

async function uploadFile(fileUpload: any) {
  const blobClient = new blobData.BlockBlobClient(fileUpload.uploadUrl);
  console.log(blobClient);

  let url = `/files/${fileUpload.fileDiscName}`;

  let blobOptions = { blobHTTPHeaders: fileUpload.uploadHeaders};

  fetch(url)
    .then((response) => response.blob())
    .then(async (blob) => {
      return await blobClient.uploadBrowserData(blob,blobOptions);
    });
}

async function uploadFiles(): Promise<boolean> {
  for (let index = 0; index < fileData.length; index++) {
    const fileUpload: any = await createFile(index);
    await uploadFile(fileUpload);
    await sleep(3000);
  }
  return true;
}
</script>
<style lang="scss">
@import "./XtSimulator.scss";
</style>